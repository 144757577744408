body {
	font-family: $font-body;
}

@include headings(1, 6) {
	font-family: $font-headings;
}

@include headings(1, 4) {
	font-weight: 700;
	line-height: 1.15;
	margin: 0 0 0.5em 0;

	/* * + & {
		margin-top: 2em;
	} */
}

@include headings(5, 6) {
	font-size: 600;
	margin: 0 0 0.5em 0;

	* + & {
		margin-top: 1.65em;
	}
}

h2,
h3.-size-h2 {
	font-size: rem(33px);
	font-weight: bold;
}

h3 {
	font-size: 1.875rem;
}

h4 {
	font-size: 1.625rem;
}

h5 {
	font-size: 1.375rem;
}

h6 {
	font-size: 1.125rem;
}

@include link($visited: false) {
	color: $color-anchors;
	text-decoration: underline;
}

@include link-visited {
	color: lighten($color-anchors, 10%);
}

@include link-over {
	text-decoration: none;
}
