.list {
	list-style-type: none;
	padding: 0;
	margin: 0 0 2rem;

	.list {
		padding: 0 0 0 2rem;
	}

	&__item {
		margin: 0 0 0.7rem;
	}

	&__description {
		margin-top: 0.25rem;
	}
}

.news-block {
	.list {
		display: grid;
		grid-gap: 30px clamp(25px, 3.5vw, 60px);
		gap: 30px clamp(25px, 3.5vw, 60px);

		@include min(800px) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		&__item {
			display: flex;
			flex-direction: column;
			box-shadow: 0 30px 184px rgba(#000, 0.15);
			background-color: $news-card-bg-color;

			.card {
				flex-grow: 1;

				@include minmax(500px, 799px) {
					display: grid;
					grid-template-columns: 230px 1fr;
					align-items: stretch;
				}
			}

			.card__image img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;

				@include min(800px) {
					aspect-ratio: 430 / 280;
					height: auto;
				}
			}
		}
	}
}

.section.homepage-tiles {
	position: relative;
	padding-top: 30px;
	padding-bottom: 0;
	z-index: 2;

	//kvůli administraci
	.vismo-left-buttons-together li[data-group-element-id] {
		&::before {
			content: normal !important;
		}

		.vismo-checkbox,
		.vismo-context-button {
			z-index: 30;
			left: 0 !important;
		}

		.vismo-checkbox {
			top: 1.75rem;
			background-color: #eee;
			width: 20px;
			padding: 0 .3rem .2rem;
			box-sizing: content-box;
			border-radius: 0 0 5px 5px;
			font-size: 1rem;
		}
	}

	&.homepage-tiles--default {
		.homepage-tiles {
			@include min(600px) {
				padding-top: 0;
				margin-top: -4rem;
			}

			@include min(1024px) {
				margin-top: -8rem;
			}

			.list {
				@include grid(1px 1 22px, 500px 2 14px, 1024px 4 28px);

				@include min(1660px) {
					margin-left: -35px;
					margin-right: -35px;
					//padding-left: 0;
				}

				&__item {
					padding: clamp(20px, 0.5vw, 34px) clamp(20px, 3vw, 52px);
					position: relative;
					z-index: 4;
					margin: 0;
					min-height: 110px;
					background-color: $tile-bg-color;
					box-shadow: -6px 6px 103px rgba(#000, 0.32);
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;

					@include min(600px) {
						min-height: 130px;
					}

					&::before {
						content: "";
						background-color: inherit;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: -1;
						transition: transform 0.16s ease-out,
						background-color 0.16s ease-out;
						transform: scaleY(1);
					}

					&::after {
						content: "Více informací";
						text-transform: lowercase;
						display: inline-block;
						transition: color 0.16s ease-out, margin 0.16s ease-out;
						overflow: hidden;
						font-size: rem(14px);
						margin-top: -1rem;
						font-weight: bold;
						padding-right: 12px;
						color: transparent;
						background: transparent encode-svg('<svg width="7" height="11" viewBox="0 0 7 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M0,1.412l1.16,-1.265l5.84,5.353l-5.84,5.353l-1.16,-1.265l4.46,-4.088l-4.46,-4.088Z" fill="#{$tile-color-hover}"/></svg>'
							) no-repeat 100% 50% / 0 0;
					}

					&:hover,
					&:focus-within {
						background-color: $tile-bg-color-hover;

						&::before {
							transform: scaleY(1.2);
						}

						&::after {
							color: $tile-color-hover;
							margin-top: 5px;
							background-size: auto;
						}

						/* @include link-all(&) {
					color: white;
				} */
					}
				}

				.homepage-tile-link {
					position: static;
					display: block;
					transition: color 0.16s ease-out;

					&__description {
						display: none;
					}

					&::after {
						content: "";
						@include fill;
					}

					@include link(&) {
						text-decoration: none;
						font-size: rem(19px);
						color: $tile-color;
					}

					@include link-over(&) {
						color: $tile-color-hover;

						&::after {
							transform: scaleY(1.2);
						}
					}
				}
			}
		}
	}

	&.homepage-tiles--with-description {
		padding-top: 0;

		.homepage-tiles {}

		.list {
			@include grid(1px 1 22px, 500px 2 14px, 1024px 4 28px, 1300px 5 30px);

			&__item {
				padding: clamp(20px, 2.5vw, 34px) 0;
				position: relative;
				z-index: 4;
				margin: 0;
				background-color: transparent;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				transform: translateY(0);
				transition: transform 0.16s ease-out;

				&::before {
					content: "";
					background-color: inherit;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: -1;
					transition: left 0.16s ease-out, right 0.16s ease-out,
						background-color 0.16s ease-out;
				}

				&:hover,
				&:focus-within {
					background-color: $tile-bg-color-hover;
					box-shadow: -6px 6px 103px rgba(#000, 0.32);
					z-index: 5;

					body:not([vuedart-edit="true"]) & {
						transform: translateY(-40px);
					}

					&::before {
						left: -14px;
						right: -14px;

						@include min(1024px) {
							left: -28px;
							right: -28px;
						}

						@include min(1300px) {
							left: -30px;
							right: -30px;
						}
					}

					&::after {
						content: "";
						height: 40px;
						display: block;
						position: absolute;
						left: 0;
						right: 0;
						top: 100%;
					}

					/* @include link-all(&) {
					color: white;
				} */
				}
			}

			.homepage-tile-link {
				position: static;
				display: flex;
				flex-direction: column;
				transition: color 0.16s ease-out;

				&::before {
					content: "";
					@include fill;
				}

				&__description {
					font-size: rem(14px);
					display: flex;
					flex-direction: column;
					margin: 6px 0 0;
				}

				&__description::before {
					content: "";
					width: 70px;
					height: 6px;
					margin: 0 0 20px;
					background-color: $tile-color;
					border-radius: 3px;
				}

				@include link(&) {
					text-decoration: none;
					font-size: rem(23px);
					font-weight: bold;
					color: $tile-color;
				}

				@include link-over(&) {
					color: $tile-color-hover;

					.homepage-tile-link__description::before {
						background-color: $tile-color-hover;
					}
				}
			}
		}
	}
}

.section.homepage-gallery {
	.list {
		display: grid;
		grid-gap: 24px;
		gap: 24px;

		@include min(550px) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto;
		}

		@include min(1024px) {
			grid-template-columns: repeat(9, 1fr);
			grid-template-rows: 3fr 2fr;
		}

		&__item {
			position: relative;
			background: black;
			margin: 0;
			aspect-ratio: 3 / 2;
			display: flex;
			flex-direction: column;

			.homepage-gallery__article {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
			}

			@include minmax(550px, 1023px) {
				&:nth-child(4) {
					grid-row: span 2;
					aspect-ratio: initial;
				}
			}

			@include min(1024px) {
				aspect-ratio: initial;

				&:first-child {
					grid-column: span 4;
					grid-row: 1;
				}

				&:nth-child(2) {
					grid-column: span 2;
					grid-row: 1;
				}

				&:nth-child(3) {
					grid-column: span 3;
					grid-row: 1 / span 2;
					aspect-ratio: 46 / 71;
				}

				&:nth-child(4) {
					grid-column: 1 / span 3;
					grid-row: 2;
				}

				&:nth-child(5) {
					grid-column: span 3;
					grid-row: 2;
				}
			}

			img {
				position: absolute;
				flex-grow: 1;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}
}
