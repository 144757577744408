[class*="lazy-"] {
	transition: filter 0.5s ease-out;
	filter: blur(20px);
}

[class*="lazy-"]:not(.loaded) {
	display: block;
	background: #dedede;
	width: 100%;
	min-width: 240px;
	padding-bottom: 56.25%;
}

[class*="lazy-"].loaded {
	filter: blur(0);
}

body {
	color: #383433;
}

::selection {
	background-color: #1a86d0;
	color: #ffffff;
}

.main-wrapper {
	padding-bottom: 40px;
	@include min(800px) {
		@include flex(df, fdrr);
		padding-bottom: 95px;
	}
}

.main {
	padding-top: rem(50px);

	@include min(800px) {
		flex-grow: 1;
		padding-bottom: 4em;
		&:not(.-nosidebar &){
			padding-left: 4vw;
		}
	}
}

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
	margin-bottom: 2em;

	table {
		margin-bottom: 1em;
	}
}

.home {
	display: flex;
	flex-direction: column;
}

.sidebar:not(.-nosidebar .sidebar) {
	position: relative;
	padding-top: 3.5rem;
	padding-bottom: 2em;
	min-width: 340px;

	@include min(800px) {
		width: ratio($max-width, 366px);
		flex-shrink: 0;
		border-right: 10px $sidebar-border-color solid;

		&::after {
			content: normal;
		}
	}
}

.content {
	margin-left: auto;
	margin-right: auto;
}

.section {
	padding: 10vw 0;

	@include min(800px) {
		padding: 5vw 0;
	}

	@include min($max-width) {
		padding: 4em 0;
	}
}

.centered {
	max-width: $max-width;
	width: 94%;
	margin-left: auto;
	margin-right: auto;

	&.-narrow {
		max-width: 920px;
	}

	&.-padded {
		width: 100%;
		padding-left: 4%;
		padding-right: 4%;
	}

	&.-padded-left {
		padding-left: 4%;
	}

	&.-padded-right {
		padding-right: 4%;
	}
}

.more {
	text-align: center;

	&__button {
		position: relative;
		z-index: 1;
		padding: 1em 0;
		display: inline-block;
		font-weight: 700;
		font-size: rem(16px);
		transition: color 0.16s ease-in-out;
		&::before {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			content: "";
			height: 6px;
			border-radius: 8px;
			background-color: $more-border-color;
			transform: scaleX(1);
			transition: height 0.16s ease-in-out, transform 0.16s ease-out;
			z-index: -1;
		}
	}
}

@include link(".more__button") {
	color: $more-color;
	text-decoration: none;
}

@include link-over(".more__button") {
	color: $more-color-hover;

	&::before {
		height: 100%;
		transform: scaleX(1.3);
	}
}

.text-component {
	margin-bottom: 2em;
	overflow: hidden;
	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}

	.image-align-left,
	.image-align-right {
		margin: 0;

		img {
			@include min(500px) {
				max-width: 42.5%;
			}
		}
	}

	.image-align-left {
		img {
			float: left;
			margin: 0 1em 0.75em 0;
		}

		> *:first-of-type img {
			clear: both;
		}
	}

	.image-align-right {
		img {
			float: right;
			margin: 0 0 0.75em 1em;
		}

		> *:first-of-type img {
			clear: both;
		}
	}

	.image-align-center {
		text-align: center;

		img {
			margin: 0 0 0.75em;
			max-width: 100%;
		}
	}

	[contenteditable="true"] {
		.images-container,
		.image {
			&:focus,
			&:hover {
				img {
					box-shadow: 0 0 5px red;
				}
			}
		}
	}
}
