.header {
	position: relative;
	color: $header-color;
	padding: 1.25rem 0;

	&.-page-header {
		box-shadow: 0 0 147px rgba(#000, 0.69); // nice
	}

	&__title {
		position: relative;
		margin: 0 auto;

		@include min(500px) {
			margin: 0;
		}
	}

	&__home-link {
		@include fill;
	}

	&__content {
		position: relative;
		z-index: 2;
		max-width: 1760px;
		//@include flex(df, fww, jcsb);
		display: grid;
		align-items: center;
		grid-gap: 25px;
		gap: 25px;

		@include minmax(800px, 1399px) {
			grid-template-columns: 1fr 1fr;

			.header__heading-container {
				grid-column: 1;
				grid-row: 1;
			}

			.search-gte-container {
				grid-column: 2;
				grid-row: 1;
			}

			.main-nav {
				grid-column: 1 / -1;
				grid-row: 2;
			}
		}

		@include min(1400px) {
			grid-template-columns: max-content 1fr max-content;
		}
	}

	&__home-link {
		color: inherit;
		text-decoration-color: none;
	}
	&__logo {
		display: block;
		margin: 0 0 0.75rem;
		font-size: 1rem;
		@include min(500px) {
			display: inline-block;
			margin: 0 1.5rem 0 0;
		}
	}

	&__heading {
		position: relative;
		text-align: center;
		margin: 0;
		padding: 0;
		color: $header-home-heading-color;
		text-align: center;
		line-height: 1;
		font-size: rem(40px);
	}
	&__subheading {
		display: block;
		margin-top: 0.5em;
		font-size: rem(15px);
		color: $header-home-subheading-color;
	}
	&__heading-container {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		@include min(500px) {
			justify-content: flex-start;
			flex-direction: row;
			.header__heading {
				text-align: left;
			}
		}
	}
}

.facebook__link{
	@include flex(df, aic, jcc);
	background-color: #d9d9d9;
	border-radius: 12px;
	height: 46px;
	width: 46px;
	@include min(600px) {
		height: 52px;
		width: 52px;
	}
}
