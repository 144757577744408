
.contact {
	&__line {
		padding-left: 50px;
		margin: 0 0 1em;

		&.-location,
		&.-phone,
		&.-email {
			padding-top: .15em;
			padding-bottom: .15em;
			background: {
				repeat: no-repeat;
				position: 0 50%;
			}
		}

		&.-location {
			background-image: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" fill="#{$contact-ico-color}" xmlns:v="https://vecta.io/nano"><path d="M15 .4C9.1.4 4.3 5.2 4.3 11.1s8.9 18.5 10.6 18.5S25.7 17 25.7 11.1 20.9.4 15 .4zm0 15.9c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2-2.3 5.2-5.2 5.2z"/></svg>');
		}

		&.-phone {
			background-image: encode-svg('<svg width="30" height="30" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M221.594,1599.46L204.711,1599.46C204.014,1599.46 203.449,1598.9 203.449,1598.2L203.449,1570.12C203.449,1569.42 204.014,1568.85 204.711,1568.85L221.594,1568.85C222.291,1568.85 222.856,1569.42 222.856,1570.12L222.856,1598.2C222.856,1598.9 222.295,1599.46 221.594,1599.46ZM213.124,1594.92C213.913,1594.92 214.552,1595.56 214.552,1596.35C214.552,1597.14 213.913,1597.78 213.124,1597.78C212.336,1597.78 211.697,1597.14 211.697,1596.35C211.697,1595.56 212.336,1594.92 213.124,1594.92ZM219.169,1571.7L207.134,1571.7C206.498,1571.7 205.988,1572.22 205.988,1572.85L205.988,1591.76C205.988,1592.39 206.501,1592.9 207.134,1592.9L219.169,1592.9C219.803,1592.9 220.315,1592.39 220.315,1591.76L220.315,1572.85C220.315,1572.22 219.805,1571.7 219.169,1571.7ZM23.166,29.805L6.834,29.805C6.16,29.805 5.613,29.259 5.613,28.584L5.613,1.416C5.613,0.742 6.16,0.195 6.834,0.195L23.166,0.195C23.84,0.195 24.387,0.742 24.387,1.416L24.387,28.584C24.387,29.257 23.843,29.802 23.166,29.805ZM14.973,25.412C15.735,25.412 16.354,26.03 16.354,26.793C16.354,27.556 15.735,28.175 14.973,28.175C14.21,28.175 13.592,27.556 13.592,26.793C13.592,26.03 14.21,25.412 14.973,25.412ZM20.82,2.949L9.178,2.949C8.563,2.951 8.069,3.447 8.069,4.057L8.069,22.352C8.069,22.964 8.566,23.461 9.178,23.461L20.82,23.461C21.432,23.461 21.928,22.964 21.928,22.352L21.928,4.057C21.928,3.447 21.435,2.951 20.82,2.949Z" fill="#{$contact-ico-color}"/></svg>');
		}

		&.-email {
			background-image: encode-svg('<svg width="30" height="30" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M26.198,22.835L3.802,22.835C3.622,22.835 3.449,22.806 3.287,22.753L10.652,15.388L14.704,17.482L14.856,17.778L15.068,17.669L15.151,17.713L15.212,17.596L19.389,15.455L26.694,22.759C26.538,22.808 26.371,22.835 26.198,22.835ZM20.94,14.66L27.857,11.116L27.857,21.176C27.857,21.3 27.843,21.421 27.817,21.537L20.94,14.66ZM9.105,14.589L2.178,21.516C2.155,21.407 2.143,21.293 2.143,21.176L2.143,10.992L9.105,14.589ZM15.07,15.804L2.143,9.125L2.143,8.824C2.143,7.908 2.886,7.165 3.802,7.165L26.198,7.165C27.114,7.165 27.857,7.908 27.857,8.824L27.857,9.253L15.07,15.804Z" fill="#{$contact-ico-color}"/></svg>');
		}
	}
}
