.breadcrumbs {
	background-color: $breadcrumbs-bg-color;
	text-align: right;
	color: $breadcrumbs-color;
	padding-top: 1em;
	padding-bottom: 1.1em;

	.centered {
		padding-left: 0;
		padding-right: 0;
	}

	@include min(800px) {
		padding-top: 2.6em;
		padding-bottom: 2.4em;
	}
}

.breadcrumb-nav {
	margin-top: 0;
	margin-bottom: 0;

	&__item {
		display: inline;

		&:not(.-active)::after {
			color: $breadcrumbs-color;
			content: " \>";
			padding: 0 0.5rem;
		}
	}

	&__link {
		font-weight: 500;
		text-decoration: underline;
		display: inline-block;
	}

	@include link(".breadcrumb-nav__link") {
		color: $breadcrumbs-color;
		text-decoration: underline;
	}

	@include link(".breadcrumb-nav__link.-active") {
		color: $breadcrumbs-color-active;
		pointer-events: none;
		font-weight: 700;
		text-decoration: none;
	}

	@include link-over(".breadcrumb-nav__link") {
		text-decoration: none;
	}
}
