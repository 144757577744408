.sidebar {
	nav.nav {
		@include min(800px) {
			margin-right: -10px;
		}
	}

	.nav {
		padding: 0 0 0 0;
		margin-top: 0;
		list-style-type: none;

		&.-root-nav {
			padding-top: rem(32px);
		}

		&__upper_node_link {
			position: relative;
			font-family: $font-headings;
			font-size: rem(30px);
			font-weight: 800;

			&::before {
				position: absolute;
				left: -30px;
				top: 50%;
				transform: translateY(calc(-50% + 2px));
				content: encode-svg(
					'<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11px" height="24px" style="fill:#{$sidebar-nav-color-active};transform:rotate(180deg)" viewBox="0 0 11 24" enable-background="new 0 0 11 24" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M11,12L0,24l5.5-12L0,0L11,12z" fill="#{$sidebar-nav-color-active}"/></svg>'
				);

				@include min(800px) {
					left: -2vw;
				}
			}

			@include link(&) {
				color: $main-nav-upper-link-color;
				text-decoration: none;
			}

			@include link-over(&) {
				text-decoration: underline;
			}
		}

		&.nav.-sub-nav {
			margin-top: 0;
			margin-bottom: 0;
			padding-left: 24px;
		}

		&__list {
			padding-left: 0em;
			list-style-type: none;
			margin: 2.2em 0 1.5em;
		}

		&__item {
			font-size: rem(15px);
			font-weight: 600;
			margin: 0;

			.nav__item {
				font-size: rem(16px);
				font-weight: 300;
				text-transform: none;
			}
			/* &.-has-children {
				> .nav__link {
					font-weight: 500;
				}
			} */

			&.-expanded {
				background-color: rgba(0, 0, 0, 0.1);
			}

			&.-current {
				background-color: rgba(0, 0, 0, 0.1);
			}
		}

		&__description {
			display: none;
			//margin: 0 0 0.25em;
			//font-size: 1rem;
		}

		&__link {
			position: relative;
			display: block;
			padding: 8px 0;

			+ .nav.-sub-nav {
				.nav__link.-expandable .nav__toggle-button {
					left: -26px;
					top: 0.36em;
					width: 12px;
					height: 12px;
					background-image: encode-svg(
						'<svg width="12" height="12" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M5,5L5,1C5,0.448 5.448,0 6,0C6.552,0 7,0.448 7,1L7,5L11,5C11.552,5 12,5.448 12,6C12,6.552 11.552,7 11,7L7,7L7,11C7,11.552 6.552,12 6,12C5.448,12 5,11.552 5,11L5,7L1,7C0.448,7 0,6.552 0,6C0,5.448 0.448,5 1,5L5,5Z"/></svg>'
					);
				}
			}

			@include link(&) {
				color: $sidebar-nav-color;
				text-decoration: none;
			}

			@include link-over(&) {
				text-decoration: underline;
			}

			&[aria-expanded="true"] ~ .nav {
				display: block;
			}

			&[aria-expanded="false"] ~ .nav {
				display: none;
			}

			&.-active,
			&.-active-parent {
				//cursor: default;
				text-decoration: none;
				//pointer-events: none;
				font-weight: bold;
				position: relative;

				@include min(800px) {
					&::after {
						content: "";
						position: absolute;
						width: 10px;
						right: 0;
						top: 0;
						bottom: 0;
						background-color: $sidebar-nav-color-active;
					}
				}

				@include link(&) {
					color: $sidebar-nav-color-active;
				}
			}
		}

		&__toggle-button {
			font-size: 1rem;
			line-height: 0;
			position: absolute;
			transition: transform 0.2s ease-out;
			transform-origin: 50% 50%;
			left: -28px;
			top: 0.18em;
			width: 16px;
			height: 16px;
			cursor: pointer;
			background: transparent
				encode-svg(
					'<svg width="16" height="16" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M7,7L7,1C7,0.448 7.448,0 8,0C8.552,0 9,0.448 9,1L9,7L15,7C15.552,7 16,7.448 16,8C16,8.552 15.552,9 15,9L9,9L9,15C9,15.552 8.552,16 8,16C7.448,16 7,15.552 7,15L7,9L1,9C0.448,9 0,8.552 0,8C0,7.448 0.448,7 1,7L7,7Z"/></svg>'
				)
				no-repeat 0 0;
		}

		&[aria-expanded="true"] .nav__toggle-button {
			transform: rotate(225deg);
		}
	}
}
