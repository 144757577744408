.search-gte-container {
	display: flex;
	//flex-wrap: wrap;
	align-items: center;
	flex-direction: row;
	gap: 14px;

	@include min(600px) {
		//flex-direction: column-reverse;
		justify-content: center;
	}
}

.search {
	position: relative;
	z-index: 3;
	@include flex(df, aic, fg0);
	padding: 1em 0 1em;
	margin-left: auto;
	width: 100%;

	@include min(600px) {
		width: auto;
	}

	&__form {
		flex-grow: 1;
		position: relative;
	}

	&__label {
		display: block;
	}

	&__input {
		width: 100% !important;
		height: 46px !important;
		font-size: rem(17px);
		font-weight: 300;
		color: #252525;
		transition: border-color 0.15s ease;
		background-color: $search-bg !important;
		padding: 5px 58px 5px 1rem !important; //important kvůli google search
		background-position-x: 1rem !important;
		border: 0 !important; //important kvůli google search
		text-indent: 0 !important; //important kvůli google search
		border-radius: 12px;

		@include min(1024px) {
			padding: 5px 58px 5px 1.25rem !important; //important kvůli google search
			background-position-x: 1.25rem !important;
		}
	}

	&__button__label,
	&__button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		color: $color-dark;
		margin-left: 1em;
		padding-top: 0.725em;
		padding-bottom: 0.725em;
		border: 0;
		cursor: pointer;
	}

	&__button {
		display: block;
		padding: 0;
		margin: 0;
		width: 22px;
		height: 22px;
		background: $search-bg
			encode-svg(
				'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.8 43.2" fill="#{$search-button-color}"><path d="M20.533 28.908c5.067 0 9.2-4.133 9.2-9.2 0-5.067-4.133-9.2-9.2-9.2-5.066 0-9.2 4.133-9.2 9.2.134 5.067 4.134 9.2 9.2 9.2M33.6 35.441l-5.067-5.066-.266-.267-.8.533c-2.534 1.6-5.2 2.267-8.267 2-5.6-.533-10.133-4.666-11.333-10.266-.134-.534-.134-1.2-.267-1.734v-1.6l.4-1.6c.8-4.133 3.067-7.066 6.667-9.066 2.266-1.2 4.666-1.6 7.2-1.334 5.333.534 9.733 4.267 11.066 9.467 1.067 3.867.4 7.467-1.866 10.667-.134.133-.134.266-.267.4.133 0 .267.133.267.133l5.333 5.333c.933.934.667 2.4-.4 3.067-.133.133-.4.133-.533.133h-.534c-.533-.133-.933-.4-1.333-.8"/></svg>'
			)
			no-repeat center center / 32px;
	}
	@include min(600px) {
		width: 236px;
		&__input {
			height: 52px !important;
		}
	}
}
