@charset "UTF-8";
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/
/*
* BASE DEPENDENCIES
*/
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap");
/*
* SHARED
*/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/*==============================
	DEFAULTS
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	CALENDAR -PAGE
==============================*/
@keyframes fade-into-foreground {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes fade-into-background {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.75);
    opacity: 0;
  }
}
@keyframes pop-into-foreground {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pop-into-foreground-with-bounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  72% {
    transform: scale(1.07);
    opacity: 0.72;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
  }
}
body {
  font-family: "Figtree", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Figtree", sans-serif;
}

h1, h2, h3, h4 {
  font-weight: 700;
  line-height: 1.15;
  margin: 0 0 0.5em 0;
  /* * + & {
  	margin-top: 2em;
  } */
}

h5, h6 {
  font-size: 600;
  margin: 0 0 0.5em 0;
}
* + h5, * + h6 {
  margin-top: 1.65em;
}

h2,
h3.-size-h2 {
  font-size: 2.0625rem;
  font-weight: bold;
}

h3 {
  font-size: 1.875rem;
}

h4 {
  font-size: 1.625rem;
}

h5 {
  font-size: 1.375rem;
}

h6 {
  font-size: 1.125rem;
}

a,
a:link {
  color: #36393c;
  text-decoration: underline;
}

a:visited {
  color: #4e5357;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

._fsr {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

._nls {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

/*
* COMMON BLOCKS
*/
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  font-family: "Figtree", sans-serif;
  background-color: #fefefe;
}

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}
.background--top {
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: cover !important;
}
@media screen and (min-width: 600px) {
  .background--top {
    background: #fff url(/images/local/v-202410141259/bgtop.png);
  }
}
.background--bottom {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: bottom !important;
}
@media screen and (min-width: 600px) {
  .background--bottom {
    background: #fff url(/images/local/v-202410141259/bg.png);
  }
}

.centered {
  max-width: 1420px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  margin: 0 -2.25vw 3em;
}
.grid__item {
  flex-grow: 0;
  padding: 2.25vw;
}
.grid__item.-fl {
  display: flex;
  flex-direction: column;
}
.grid__item.-fl > :only-child {
  flex-grow: 1;
}
.grid.-no-spacing {
  margin-left: 0;
  margin-right: 0;
}
.grid.-no-spacing .grid__item {
  padding: 0;
}
.grid.-halves {
  display: grid;
}
@media screen and (min-width: 649px) {
  .grid.-halves {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
.grid.-thirds {
  display: grid;
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .grid.-thirds {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 799px) {
  .grid.-thirds {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 800px) {
  .grid.-thirds:not(.-no-spacing) {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-thirds:not(.-no-spacing) .grid__item {
    padding: 1.6875vw;
  }
}
.grid.-quarters {
  display: grid;
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .grid.-quarters {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 800px) and (max-width: 1024px) {
  .grid.-quarters {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 1023px) {
  .grid.-quarters {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (min-width: 800px) {
  .grid.-quarters:not(.-no-spacing) {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-quarters:not(.-no-spacing) .grid__item {
    padding: 1.6875vw;
  }
}
@media screen and (min-width: 1024px) {
  .grid.-quarters:not(.-no-spacing) {
    margin-left: -1.125vw;
    margin-right: -1.125vw;
  }
  .grid.-quarters:not(.-no-spacing) .grid__item {
    padding: 1.125vw;
  }
}

.agnostic-grid {
  margin: 0 0 3em;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
}

a:focus.keyboard-focus,
video:focus.keyboard-focus,
button:focus.keyboard-focus,
summary:focus.keyboard-focus {
  outline: 3px lightskyblue solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.textarea, .input {
  transition: border-color 0.11s ease, box-shadow 0.21s ease;
  box-shadow: 0;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  border-color: #cccccc;
  border-width: 1px;
  background-color: #ffffff;
  color: #2a2a2a;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.textarea:focus, .input:focus {
  border-color: #6b9aeb;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}
[disabled].textarea, [disabled].input {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #444444;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
[class*=lazy-] {
  transition: filter 0.5s ease-out;
  filter: blur(20px);
}

[class*=lazy-]:not(.loaded) {
  display: block;
  background: #dedede;
  width: 100%;
  min-width: 240px;
  padding-bottom: 56.25%;
}

[class*=lazy-].loaded {
  filter: blur(0);
}

body {
  color: #383433;
}

::selection {
  background-color: #1a86d0;
  color: #ffffff;
}

.main-wrapper {
  padding-bottom: 40px;
}
@media screen and (min-width: 800px) {
  .main-wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 95px;
  }
}

.main {
  padding-top: 3.125rem;
}
@media screen and (min-width: 800px) {
  .main {
    flex-grow: 1;
    padding-bottom: 4em;
  }
  .main:not(.-nosidebar .main) {
    padding-left: 4vw;
  }
}

.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 2em;
}
.table-wrapper table {
  margin-bottom: 1em;
}

.home {
  display: flex;
  flex-direction: column;
}

.sidebar:not(.-nosidebar .sidebar) {
  position: relative;
  padding-top: 3.5rem;
  padding-bottom: 2em;
  min-width: 340px;
}
@media screen and (min-width: 800px) {
  .sidebar:not(.-nosidebar .sidebar) {
    width: 25.7746478873%;
    flex-shrink: 0;
    border-right: 10px #edeff4 solid;
  }
  .sidebar:not(.-nosidebar .sidebar)::after {
    content: normal;
  }
}

.content {
  margin-left: auto;
  margin-right: auto;
}

.section {
  padding: 10vw 0;
}
@media screen and (min-width: 800px) {
  .section {
    padding: 5vw 0;
  }
}
@media screen and (min-width: 1420px) {
  .section {
    padding: 4em 0;
  }
}

.centered {
  max-width: 1420px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}
.centered.-narrow {
  max-width: 920px;
}
.centered.-padded {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
}
.centered.-padded-left {
  padding-left: 4%;
}
.centered.-padded-right {
  padding-right: 4%;
}

.more {
  text-align: center;
}
.more__button {
  position: relative;
  z-index: 1;
  padding: 1em 0;
  display: inline-block;
  font-weight: 700;
  font-size: 1rem;
  transition: color 0.16s ease-in-out;
}
.more__button::before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  height: 6px;
  border-radius: 8px;
  background-color: #addb67;
  transform: scaleX(1);
  transition: height 0.16s ease-in-out, transform 0.16s ease-out;
  z-index: -1;
}

.more__button,
.more__button:link,
.more__button:visited {
  color: #637600;
  text-decoration: none;
}

.more__button:hover,
.more__button:focus,
.more__button:active {
  color: #36393c;
}
.more__button:hover::before,
.more__button:focus::before,
.more__button:active::before {
  height: 100%;
  transform: scaleX(1.3);
}

.text-component {
  margin-bottom: 2em;
  overflow: hidden;
}
.text-component p:first-child {
  margin-top: 0;
}
.text-component p:last-child {
  margin-bottom: 0;
}
.text-component .image-align-left,
.text-component .image-align-right {
  margin: 0;
}
@media screen and (min-width: 500px) {
  .text-component .image-align-left img,
  .text-component .image-align-right img {
    max-width: 42.5%;
  }
}
.text-component .image-align-left img {
  float: left;
  margin: 0 1em 0.75em 0;
}
.text-component .image-align-left > *:first-of-type img {
  clear: both;
}
.text-component .image-align-right img {
  float: right;
  margin: 0 0 0.75em 1em;
}
.text-component .image-align-right > *:first-of-type img {
  clear: both;
}
.text-component .image-align-center {
  text-align: center;
}
.text-component .image-align-center img {
  margin: 0 0 0.75em;
  max-width: 100%;
}
.text-component [contenteditable=true] .images-container:focus img, .text-component [contenteditable=true] .images-container:hover img,
.text-component [contenteditable=true] .image:focus img,
.text-component [contenteditable=true] .image:hover img {
  box-shadow: 0 0 5px red;
}

.header {
  position: relative;
  color: #444444;
  padding: 1.25rem 0;
}
.header.-page-header {
  box-shadow: 0 0 147px rgba(0, 0, 0, 0.69);
}
.header__title {
  position: relative;
  margin: 0 auto;
}
@media screen and (min-width: 500px) {
  .header__title {
    margin: 0;
  }
}
.header__home-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.header__content {
  position: relative;
  z-index: 2;
  max-width: 1760px;
  display: grid;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
}
@media screen and (min-width: 800px) and (max-width: 1399px) {
  .header__content {
    grid-template-columns: 1fr 1fr;
  }
  .header__content .header__heading-container {
    grid-column: 1;
    grid-row: 1;
  }
  .header__content .search-gte-container {
    grid-column: 2;
    grid-row: 1;
  }
  .header__content .main-nav {
    grid-column: 1/-1;
    grid-row: 2;
  }
}
@media screen and (min-width: 1400px) {
  .header__content {
    grid-template-columns: max-content 1fr max-content;
  }
}
.header__home-link {
  color: inherit;
  text-decoration-color: none;
}
.header__logo {
  display: block;
  margin: 0 0 0.75rem;
  font-size: 1rem;
}
@media screen and (min-width: 500px) {
  .header__logo {
    display: inline-block;
    margin: 0 1.5rem 0 0;
  }
}
.header__heading {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #333333;
  text-align: center;
  line-height: 1;
  font-size: 2.5rem;
}
.header__subheading {
  display: block;
  margin-top: 0.5em;
  font-size: 0.9375rem;
  color: #666666;
}
.header__heading-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 500px) {
  .header__heading-container {
    justify-content: flex-start;
    flex-direction: row;
  }
  .header__heading-container .header__heading {
    text-align: left;
  }
}

.facebook__link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  border-radius: 12px;
  height: 46px;
  width: 46px;
}
@media screen and (min-width: 600px) {
  .facebook__link {
    height: 52px;
    width: 52px;
  }
}

.page-footer,
.homepage-footer {
  text-align: center;
  background-color: #fcfcfc;
  color: #242d3c;
  padding: 3.5em 0 2em;
}
.page-footer a,
.page-footer a:link,
.page-footer a:visited,
.homepage-footer a,
.homepage-footer a:link,
.homepage-footer a:visited {
  color: #242d3c;
}

.article__heading {
  font-size: 2.75rem;
  letter-spacing: -0.02em;
  line-height: 1.15;
  margin: 0 0 0.75em;
  font-weight: 600;
  color: #242424;
}
.article__approved {
  padding: 1em 0 0;
}
.article__footer {
  margin: 1.5em 0;
}
.article__footer div {
  display: inline-block;
}
.article__footer div input[type=date],
.article__footer div input[type=time] {
  display: inline;
  border: 0;
  padding: 0 0.5em;
  border-radius: 3px;
}
.article__footer .invalid ul {
  margin: 0;
  padding: 0 0 0 0.5em;
}
.article__perex {
  font-size: 1.1em;
  margin-bottom: 1.5em;
}
.article__image {
  margin-left: auto;
  margin-right: auto;
}
.article__body {
  margin-bottom: 0em;
}
.article__author {
  text-align: left;
  clear: both;
  margin: 1.5em 0;
  display: inline;
  font-style: normal;
}
.article__datespan {
  margin-bottom: 3em;
}
.article__date {
  font-weight: bold;
}
.article .text-component {
  max-width: 80ch;
}

.article-link__image {
  display: block;
  max-width: 240px;
  margin: 0 0 1rem;
  flex-shrink: 0;
}
.article__link-elements .article-link__image {
  max-width: 100px;
}
@media screen and (min-width: 500px) {
  .article-link__image {
    display: inline-block;
    float: left;
    margin: 0 32px 0 0;
  }
}
.article-link__title {
  font-size: 1.125rem;
}
.article-link__description {
  font-size: 1rem;
  margin-top: 0;
}

.article-link,
.article-link:link {
  color: #242424;
  text-decoration: underline;
}

.article-link:visited {
  color: #0b0b0b;
}

.article-link:hover,
.article-link:focus,
.article-link:active {
  text-decoration: none;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem;
}
.list .list {
  padding: 0 0 0 2rem;
}
.list__item {
  margin: 0 0 0.7rem;
}
.list__description {
  margin-top: 0.25rem;
}

.news-block .list {
  display: grid;
  grid-gap: 30px clamp(25px, 3.5vw, 60px);
  gap: 30px clamp(25px, 3.5vw, 60px);
}
@media screen and (min-width: 800px) {
  .news-block .list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.news-block .list__item {
  display: flex;
  flex-direction: column;
  box-shadow: 0 30px 184px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
.news-block .list__item .card {
  flex-grow: 1;
}
@media screen and (min-width: 500px) and (max-width: 799px) {
  .news-block .list__item .card {
    display: grid;
    grid-template-columns: 230px 1fr;
    align-items: stretch;
  }
}
.news-block .list__item .card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media screen and (min-width: 800px) {
  .news-block .list__item .card__image img {
    aspect-ratio: 430/280;
    height: auto;
  }
}

.section.homepage-tiles {
  position: relative;
  padding-top: 30px;
  padding-bottom: 0;
  z-index: 2;
}
.section.homepage-tiles .vismo-left-buttons-together li[data-group-element-id]::before {
  content: normal !important;
}
.section.homepage-tiles .vismo-left-buttons-together li[data-group-element-id] .vismo-checkbox,
.section.homepage-tiles .vismo-left-buttons-together li[data-group-element-id] .vismo-context-button {
  z-index: 30;
  left: 0 !important;
}
.section.homepage-tiles .vismo-left-buttons-together li[data-group-element-id] .vismo-checkbox {
  top: 1.75rem;
  background-color: #eee;
  width: 20px;
  padding: 0 0.3rem 0.2rem;
  box-sizing: content-box;
  border-radius: 0 0 5px 5px;
  font-size: 1rem;
}
@media screen and (min-width: 600px) {
  .section.homepage-tiles.homepage-tiles--default .homepage-tiles {
    padding-top: 0;
    margin-top: -4rem;
  }
}
@media screen and (min-width: 1024px) {
  .section.homepage-tiles.homepage-tiles--default .homepage-tiles {
    margin-top: -8rem;
  }
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list {
  display: grid;
}
@media screen and (min-width: 1px) and (max-width: 500px) {
  .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 22px;
    gap: 22px;
  }
}
@media screen and (min-width: 500px) and (max-width: 1024px) {
  .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px;
    gap: 14px;
  }
}
@media screen and (min-width: 1023px) {
  .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 28px;
    gap: 28px;
  }
}
@media screen and (min-width: 1660px) {
  .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list {
    margin-left: -35px;
    margin-right: -35px;
  }
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item {
  padding: clamp(20px, 0.5vw, 34px) clamp(20px, 3vw, 52px);
  position: relative;
  z-index: 4;
  margin: 0;
  min-height: 110px;
  background-color: #edeff4;
  box-shadow: -6px 6px 103px rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item {
    min-height: 130px;
  }
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item::before {
  content: "";
  background-color: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: transform 0.16s ease-out, background-color 0.16s ease-out;
  transform: scaleY(1);
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item::after {
  content: "Více informací";
  text-transform: lowercase;
  display: inline-block;
  transition: color 0.16s ease-out, margin 0.16s ease-out;
  overflow: hidden;
  font-size: 0.875rem;
  margin-top: -1rem;
  font-weight: bold;
  padding-right: 12px;
  color: transparent;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg width='7' height='11' viewBox='0 0 7 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M0,1.412l1.16,-1.265l5.84,5.353l-5.84,5.353l-1.16,-1.265l4.46,-4.088l-4.46,-4.088Z' fill='%2336393c'/%3E%3C/svg%3E") no-repeat 100% 50%/0 0;
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item:hover, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item:focus-within {
  background-color: #addb67;
  /* @include link-all(&) {
  	color: white;
  } */
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item:hover::before, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item:focus-within::before {
  transform: scaleY(1.2);
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item:hover::after, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list__item:focus-within::after {
  color: #36393c;
  margin-top: 5px;
  background-size: auto;
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link {
  position: static;
  display: block;
  transition: color 0.16s ease-out;
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link__description {
  display: none;
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link:link, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link:visited {
  text-decoration: none;
  font-size: 1.1875rem;
  color: #36393c;
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link:hover, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link:focus, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link:active {
  color: #36393c;
}
.section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link:hover::after, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link:focus::after, .section.homepage-tiles.homepage-tiles--default .homepage-tiles .list .homepage-tile-link:active::after {
  transform: scaleY(1.2);
}
.section.homepage-tiles.homepage-tiles--with-description {
  padding-top: 0;
}
.section.homepage-tiles.homepage-tiles--with-description .list {
  display: grid;
}
@media screen and (min-width: 1px) and (max-width: 500px) {
  .section.homepage-tiles.homepage-tiles--with-description .list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 22px;
    gap: 22px;
  }
}
@media screen and (min-width: 500px) and (max-width: 1024px) {
  .section.homepage-tiles.homepage-tiles--with-description .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px;
    gap: 14px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .section.homepage-tiles.homepage-tiles--with-description .list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 28px;
    gap: 28px;
  }
}
@media screen and (min-width: 1299px) {
  .section.homepage-tiles.homepage-tiles--with-description .list {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px;
    gap: 30px;
  }
}
.section.homepage-tiles.homepage-tiles--with-description .list__item {
  padding: clamp(20px, 2.5vw, 34px) 0;
  position: relative;
  z-index: 4;
  margin: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transform: translateY(0);
  transition: transform 0.16s ease-out;
}
.section.homepage-tiles.homepage-tiles--with-description .list__item::before {
  content: "";
  background-color: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: left 0.16s ease-out, right 0.16s ease-out, background-color 0.16s ease-out;
}
.section.homepage-tiles.homepage-tiles--with-description .list__item:hover, .section.homepage-tiles.homepage-tiles--with-description .list__item:focus-within {
  background-color: #addb67;
  box-shadow: -6px 6px 103px rgba(0, 0, 0, 0.32);
  z-index: 5;
  /* @include link-all(&) {
  	color: white;
  } */
}
body:not([vuedart-edit=true]) .section.homepage-tiles.homepage-tiles--with-description .list__item:hover, body:not([vuedart-edit=true]) .section.homepage-tiles.homepage-tiles--with-description .list__item:focus-within {
  transform: translateY(-40px);
}
.section.homepage-tiles.homepage-tiles--with-description .list__item:hover::before, .section.homepage-tiles.homepage-tiles--with-description .list__item:focus-within::before {
  left: -14px;
  right: -14px;
}
@media screen and (min-width: 1024px) {
  .section.homepage-tiles.homepage-tiles--with-description .list__item:hover::before, .section.homepage-tiles.homepage-tiles--with-description .list__item:focus-within::before {
    left: -28px;
    right: -28px;
  }
}
@media screen and (min-width: 1300px) {
  .section.homepage-tiles.homepage-tiles--with-description .list__item:hover::before, .section.homepage-tiles.homepage-tiles--with-description .list__item:focus-within::before {
    left: -30px;
    right: -30px;
  }
}
.section.homepage-tiles.homepage-tiles--with-description .list__item:hover::after, .section.homepage-tiles.homepage-tiles--with-description .list__item:focus-within::after {
  content: "";
  height: 40px;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
}
.section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link {
  position: static;
  display: flex;
  flex-direction: column;
  transition: color 0.16s ease-out;
}
.section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link__description {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  margin: 6px 0 0;
}
.section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link__description::before {
  content: "";
  width: 70px;
  height: 6px;
  margin: 0 0 20px;
  background-color: #36393c;
  border-radius: 3px;
}
.section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link, .section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link:link, .section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link:visited {
  text-decoration: none;
  font-size: 1.4375rem;
  font-weight: bold;
  color: #36393c;
}
.section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link:hover, .section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link:focus, .section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link:active {
  color: #36393c;
}
.section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link:hover .homepage-tile-link__description::before, .section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link:focus .homepage-tile-link__description::before, .section.homepage-tiles.homepage-tiles--with-description .list .homepage-tile-link:active .homepage-tile-link__description::before {
  background-color: #36393c;
}

.section.homepage-gallery .list {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
}
@media screen and (min-width: 550px) {
  .section.homepage-gallery .list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
}
@media screen and (min-width: 1024px) {
  .section.homepage-gallery .list {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 3fr 2fr;
  }
}
.section.homepage-gallery .list__item {
  position: relative;
  background: black;
  margin: 0;
  aspect-ratio: 3/2;
  display: flex;
  flex-direction: column;
}
.section.homepage-gallery .list__item .homepage-gallery__article {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 550px) and (max-width: 1023px) {
  .section.homepage-gallery .list__item:nth-child(4) {
    grid-row: span 2;
    aspect-ratio: initial;
  }
}
@media screen and (min-width: 1024px) {
  .section.homepage-gallery .list__item {
    aspect-ratio: initial;
  }
  .section.homepage-gallery .list__item:first-child {
    grid-column: span 4;
    grid-row: 1;
  }
  .section.homepage-gallery .list__item:nth-child(2) {
    grid-column: span 2;
    grid-row: 1;
  }
  .section.homepage-gallery .list__item:nth-child(3) {
    grid-column: span 3;
    grid-row: 1/span 2;
    aspect-ratio: 46/71;
  }
  .section.homepage-gallery .list__item:nth-child(4) {
    grid-column: 1/span 3;
    grid-row: 2;
  }
  .section.homepage-gallery .list__item:nth-child(5) {
    grid-column: span 3;
    grid-row: 2;
  }
}
.section.homepage-gallery .list__item img {
  position: absolute;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/*
* DESIGN COMPONENTS
*/
.button {
  border-radius: 4px;
  padding: 5px 2em;
  margin: 0;
  min-height: 42px;
  background-color: #edeff4;
  color: #36393c;
  text-align: center;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
}
.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
  box-shadow: 0 5px 20px #edeff4;
}
.button:not([disabled]):hover.-inverted, .button:not([disabled]):focus.-inverted, .button:not([disabled]):active.-inverted {
  box-shadow: 0 5px 20px #36393c;
}
.button[disabled] {
  color: #999999;
  background-color: #ebebeb;
  cursor: not-allowed;
}
.button.-icon::before {
  vertical-align: middle;
  margin-right: 0.75em;
  display: inline-block;
}
.button.-block {
  display: block;
  width: 100%;
}
.button.-large {
  font-size: 1.15rem;
  padding: 1em 3em;
}
.button.-inverted {
  background-color: #edeff4;
  color: #36393c;
}
.button.-primary {
  background-color: #addb67;
  box-shadow: 0 0 0 0 #addb67;
  color: #fff;
}
.button.-primary.-inverted {
  background-color: #fff;
  box-shadow: 0 0 0 0 #fff;
  color: #addb67;
}
.button.-primary:not([disabled]):hover, .button.-primary:not([disabled]):focus, .button.-primary:not([disabled]):active {
  box-shadow: 0 5px 20px #addb67;
}
.button.-primary:not([disabled]):hover.-inverted, .button.-primary:not([disabled]):focus.-inverted, .button.-primary:not([disabled]):active.-inverted {
  box-shadow: 0 5px 20px #fff;
}

.input {
  min-height: 42px;
  padding: 0.3em 1em;
}
.input.-inline {
  display: inline-block;
  width: auto;
  margin-right: 0.25em;
}
.input.-main {
  min-height: 54px;
  font-size: 1.15em;
}

.textarea {
  padding: 0.5em 1em;
  resize: vertical;
  height: auto;
  min-height: 10em;
}

.universal-popup-component {
  position: fixed;
  overflow: hidden;
  z-index: 90;
  left: -9999rem;
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #323232;
}
.universal-popup-component::before {
  position: fixed;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.15s ease-out;
}
.universal-popup-component__form {
  position: relative;
  z-index: 2;
  transform: scale(0);
  background-color: #ffffff;
  border-radius: 15px;
  transition: transform 0.15s ease-out, box-shadow 0.2s ease-out;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  max-width: 80vw;
  max-height: 70vh;
  min-height: 10em;
  width: 36em;
  padding: 3vmax;
}
.universal-popup-component__heading {
  font-family: inherit;
  color: inherit;
  margin: 0 0 0.66em;
  padding: 0;
  text-transform: none;
  font-size: 2.1875rem;
}
.universal-popup-component__row {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.universal-popup-component__row:empty {
  display: none;
}
.universal-popup-component__row:not(:last-child) {
  margin-bottom: 20px;
}
.universal-popup-component__row.-end {
  align-items: flex-end;
}
.universal-popup-component__row.-center {
  align-items: center;
}
.universal-popup-component__label {
  font-family: inherit;
  font-size: inherit;
  margin: 0 0 6px;
}
.universal-popup-component__input {
  font-family: inherit;
  font-size: inherit;
  height: 54px;
  border-radius: 6px;
  border: 1px #cccccc solid;
  outline: 0;
}
.universal-popup-component__button {
  font-family: inherit;
  font-size: inherit;
  border-radius: 5px;
  background-color: #888888;
  color: #ffffff;
}
.universal-popup-component__close {
  font-size: 1.25rem;
  font-weight: 600;
  position: absolute;
  right: 1em;
  top: 1em;
  line-height: 0;
  width: 20px;
  height: 20px;
  text-decoration: none;
}
.universal-popup-component.-visible, .universal-popup-component:target {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.universal-popup-component.-visible::before, .universal-popup-component:target::before {
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
}
@keyframes popup-bounce {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.universal-popup-component.-visible .universal-popup-component__form, .universal-popup-component:target .universal-popup-component__form {
  z-index: 2;
  animation: popup-bounce 0.3s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 100%;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}

.breadcrumbs {
  background-color: #ffffff;
  text-align: right;
  color: #383433;
  padding-top: 1em;
  padding-bottom: 1.1em;
}
.breadcrumbs .centered {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 800px) {
  .breadcrumbs {
    padding-top: 2.6em;
    padding-bottom: 2.4em;
  }
}

.breadcrumb-nav {
  margin-top: 0;
  margin-bottom: 0;
}
.breadcrumb-nav__item {
  display: inline;
}
.breadcrumb-nav__item:not(.-active)::after {
  color: #383433;
  content: " >";
  padding: 0 0.5rem;
}
.breadcrumb-nav__link {
  font-weight: 500;
  text-decoration: underline;
  display: inline-block;
}
.breadcrumb-nav .breadcrumb-nav__link,
.breadcrumb-nav .breadcrumb-nav__link:link,
.breadcrumb-nav .breadcrumb-nav__link:visited {
  color: #383433;
  text-decoration: underline;
}
.breadcrumb-nav .breadcrumb-nav__link.-active,
.breadcrumb-nav .breadcrumb-nav__link.-active:link,
.breadcrumb-nav .breadcrumb-nav__link.-active:visited {
  color: #637600;
  pointer-events: none;
  font-weight: 700;
  text-decoration: none;
}
.breadcrumb-nav .breadcrumb-nav__link:hover,
.breadcrumb-nav .breadcrumb-nav__link:focus,
.breadcrumb-nav .breadcrumb-nav__link:active {
  text-decoration: none;
}

.form {
  padding: 2em 0;
  margin: 0 0 2em 0;
}
.form__fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.form__fields > :not(.form__section) {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.form__section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 50%;
  padding: 0 1.25rem;
  min-width: 18em;
  margin-bottom: 1.1em;
}
.form__section.-inline {
  display: inline-block;
  padding: 0;
}
.form__section.-full {
  width: 100%;
  flex-basis: 100%;
}
.form__section.-to-end {
  justify-content: flex-end;
}
.form__section.-to-center, .form__section.-to-right {
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 1;
  width: 100%;
  margin-top: 1em;
}
.form__section.-to-center {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}
.form__section.-to-right {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}
.form__section.-to-left {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.form__section .label {
  margin-bottom: 0.6em;
}
.form__section .label.-inline {
  display: inline-block;
  margin-right: 0.25em;
}
.form__section .button:nth-last-child(n+2), .form__section .button:nth-last-child(n+2) ~ .button {
  margin-bottom: 0.5em;
}

.form__section .button:not(:first-child):not(.-block) {
  margin-left: 0.5em;
}
.form__label {
  display: block;
  margin-bottom: 0.4em;
  cursor: default;
}
.form__file-value {
  color: inherit;
  opacity: 0.8;
}
.form__fieldset {
  padding: 0.85em 1.5em 0.625em;
  border: 1px #cccccc solid;
  border-radius: 4px;
}
.form__legend {
  padding: 0 0.5em;
  font-size: 1.35rem;
  font-weight: bold;
}

.inline-form.form {
  padding-bottom: 0;
}
.inline-form__fields.-bordered-top {
  border-top: 2px solid #c3c3c3;
  padding-top: 1.5em;
}
.inline-form__fields.-bordered-bottom {
  border-bottom: 2px solid #c3c3c3;
  padding-bottom: 0.5em;
}

.contact-form {
  padding-top: 0;
}
.contact-form .form__section {
  margin-bottom: 14px;
}
.contact-form__input, .contact-form__textarea {
  background-color: #fff;
  border-color: #d2d2d3;
  color: #222526;
  border-radius: 0;
}
.contact-form__input::placeholder, .contact-form__textarea::placeholder {
  font-style: italic;
}
.contact-form__input {
  min-height: 55px;
}
.contact-form__info:first-child {
  margin: 0.55em 0 0.9em;
}
.contact-form__button.-large.-primary {
  appearance: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  padding: 1em 0;
  color: #637600;
  position: relative;
  font-weight: 700;
  font-size: 1rem;
  transition: color 0.16s ease-in-out;
  min-height: initial;
}
.contact-form__button.-large.-primary::before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  height: 6px;
  border-radius: 8px;
  background-color: #addb67;
  transform: scaleX(1);
  transition: height 0.16s ease-in-out, transform 0.16s ease-out;
  z-index: 0;
}
.contact-form__button.-large.-primary::after {
  content: " zprávu";
}
.contact-form__button.-large.-primary:not([disabled]):hover, .contact-form__button.-large.-primary:not([disabled]):focus, .contact-form__button.-large.-primary:not([disabled]):active {
  box-shadow: none;
  color: #36393c;
}
.contact-form__button.-large.-primary:not([disabled]):hover::before, .contact-form__button.-large.-primary:not([disabled]):focus::before, .contact-form__button.-large.-primary:not([disabled]):active::before {
  z-index: -1;
  height: 100%;
  transform: scaleX(1.3);
}

.contact__line {
  padding-left: 50px;
  margin: 0 0 1em;
}
.contact__line.-location, .contact__line.-phone, .contact__line.-email {
  padding-top: 0.15em;
  padding-bottom: 0.15em;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
.contact__line.-location {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30' fill='%23ffffff' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M15 .4C9.1.4 4.3 5.2 4.3 11.1s8.9 18.5 10.6 18.5S25.7 17 25.7 11.1 20.9.4 15 .4zm0 15.9c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2-2.3 5.2-5.2 5.2z'/%3E%3C/svg%3E");
}
.contact__line.-phone {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cpath d='M221.594,1599.46L204.711,1599.46C204.014,1599.46 203.449,1598.9 203.449,1598.2L203.449,1570.12C203.449,1569.42 204.014,1568.85 204.711,1568.85L221.594,1568.85C222.291,1568.85 222.856,1569.42 222.856,1570.12L222.856,1598.2C222.856,1598.9 222.295,1599.46 221.594,1599.46ZM213.124,1594.92C213.913,1594.92 214.552,1595.56 214.552,1596.35C214.552,1597.14 213.913,1597.78 213.124,1597.78C212.336,1597.78 211.697,1597.14 211.697,1596.35C211.697,1595.56 212.336,1594.92 213.124,1594.92ZM219.169,1571.7L207.134,1571.7C206.498,1571.7 205.988,1572.22 205.988,1572.85L205.988,1591.76C205.988,1592.39 206.501,1592.9 207.134,1592.9L219.169,1592.9C219.803,1592.9 220.315,1592.39 220.315,1591.76L220.315,1572.85C220.315,1572.22 219.805,1571.7 219.169,1571.7ZM23.166,29.805L6.834,29.805C6.16,29.805 5.613,29.259 5.613,28.584L5.613,1.416C5.613,0.742 6.16,0.195 6.834,0.195L23.166,0.195C23.84,0.195 24.387,0.742 24.387,1.416L24.387,28.584C24.387,29.257 23.843,29.802 23.166,29.805ZM14.973,25.412C15.735,25.412 16.354,26.03 16.354,26.793C16.354,27.556 15.735,28.175 14.973,28.175C14.21,28.175 13.592,27.556 13.592,26.793C13.592,26.03 14.21,25.412 14.973,25.412ZM20.82,2.949L9.178,2.949C8.563,2.951 8.069,3.447 8.069,4.057L8.069,22.352C8.069,22.964 8.566,23.461 9.178,23.461L20.82,23.461C21.432,23.461 21.928,22.964 21.928,22.352L21.928,4.057C21.928,3.447 21.435,2.951 20.82,2.949Z' fill='%23ffffff'/%3E%3C/svg%3E");
}
.contact__line.-email {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='30' height='30' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'%3E%3Cpath d='M26.198,22.835L3.802,22.835C3.622,22.835 3.449,22.806 3.287,22.753L10.652,15.388L14.704,17.482L14.856,17.778L15.068,17.669L15.151,17.713L15.212,17.596L19.389,15.455L26.694,22.759C26.538,22.808 26.371,22.835 26.198,22.835ZM20.94,14.66L27.857,11.116L27.857,21.176C27.857,21.3 27.843,21.421 27.817,21.537L20.94,14.66ZM9.105,14.589L2.178,21.516C2.155,21.407 2.143,21.293 2.143,21.176L2.143,10.992L9.105,14.589ZM15.07,15.804L2.143,9.125L2.143,8.824C2.143,7.908 2.886,7.165 3.802,7.165L26.198,7.165C27.114,7.165 27.857,7.908 27.857,8.824L27.857,9.253L15.07,15.804Z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.search-gte-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 14px;
}
@media screen and (min-width: 600px) {
  .search-gte-container {
    justify-content: center;
  }
}

.search {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-grow: 0;
  padding: 1em 0 1em;
  margin-left: auto;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .search {
    width: auto;
  }
}
.search__form {
  flex-grow: 1;
  position: relative;
}
.search__label {
  display: block;
}
.search__input {
  width: 100% !important;
  height: 46px !important;
  font-size: 1.0625rem;
  font-weight: 300;
  color: #252525;
  transition: border-color 0.15s ease;
  background-color: #d9d9d9 !important;
  padding: 5px 58px 5px 1rem !important;
  background-position-x: 1rem !important;
  border: 0 !important;
  text-indent: 0 !important;
  border-radius: 12px;
}
@media screen and (min-width: 1024px) {
  .search__input {
    padding: 5px 58px 5px 1.25rem !important;
    background-position-x: 1.25rem !important;
  }
}
.search__button__label, .search__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: #292a2d;
  margin-left: 1em;
  padding-top: 0.725em;
  padding-bottom: 0.725em;
  border: 0;
  cursor: pointer;
}
.search__button {
  display: block;
  padding: 0;
  margin: 0;
  width: 22px;
  height: 22px;
  background: #d9d9d9 url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44.8 43.2' fill='%230f0f0f'%3E%3Cpath d='M20.533 28.908c5.067 0 9.2-4.133 9.2-9.2 0-5.067-4.133-9.2-9.2-9.2-5.066 0-9.2 4.133-9.2 9.2.134 5.067 4.134 9.2 9.2 9.2M33.6 35.441l-5.067-5.066-.266-.267-.8.533c-2.534 1.6-5.2 2.267-8.267 2-5.6-.533-10.133-4.666-11.333-10.266-.134-.534-.134-1.2-.267-1.734v-1.6l.4-1.6c.8-4.133 3.067-7.066 6.667-9.066 2.266-1.2 4.666-1.6 7.2-1.334 5.333.534 9.733 4.267 11.066 9.467 1.067 3.867.4 7.467-1.866 10.667-.134.133-.134.266-.267.4.133 0 .267.133.267.133l5.333 5.333c.933.934.667 2.4-.4 3.067-.133.133-.4.133-.533.133h-.534c-.533-.133-.933-.4-1.333-.8'/%3E%3C/svg%3E") no-repeat center center/32px;
}
@media screen and (min-width: 600px) {
  .search {
    width: 236px;
  }
  .search__input {
    height: 52px !important;
  }
}

#google_translate_element {
  display: inline-block;
  margin: 0;
  align-self: center;
  padding: 16px;
  border-radius: 12px;
}
#google_translate_element.field {
  background-color: #d9d9d9;
}
#google_translate_element .goog-te-gadget-simple {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
  display: flex;
  align-items: center;
}
#google_translate_element .goog-te-gadget-simple, #google_translate_element .goog-te-gadget-simple * {
  font-family: "Figtree", sans-serif;
  font-size: 1rem !important;
}
#google_translate_element .goog-te-gadget-simple .goog-te-gadget-icon {
  padding: 9.5px;
  order: 2;
  margin: 0;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44.8 43.2' ffill='%230f0f0f'%3E%3Cpath d='M23.067 34.241h-1.6c-2-.133-3.867-.666-5.467-1.733-3.333-2-5.333-4.933-6-8.667l-.133-1.466V20.64l.266-2c1.067-3.866 3.334-6.666 6.8-8.4 2.4-1.2 4.8-1.466 7.467-1.066 1.333.266 2.667.666 3.867 1.333.666.267 1.2.667 1.733 1.067l-2.933 3.866c-3.334-2.533-7.467-1.733-9.867.267-2.533 2.267-3.467 5.867-2 9.067a8.066 8.066 0 008.267 4.666c3.333-.533 5.6-2.933 6.266-5.333h-7.466v-4.933h12.666l-.133 3.733c-.267 2.667-1.333 5.067-3.067 7.067-2 2.266-4.4 3.6-7.333 4.133l-1.333.133'/%3E%3C/svg%3E") no-repeat 50% 50%/150% !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] {
  margin-right: 0;
  display: none;
  text-decoration: none !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1) {
  padding: 0 3px;
  display: inline-block;
  color: #0f0f0f;
  text-decoration: underline;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):hover, #google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):focus, #google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(1):active {
  text-decoration: none;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(2) {
  padding-right: 3px;
  border-left: 0 !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] span:nth-of-type(3) {
  display: none;
  background: none;
  color: #0f0f0f !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup] img {
  display: none;
}

@media screen and (max-width: 599px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame,
  iframe.goog-te-banner-frame {
    display: none !important;
  }
}
.main-nav {
  position: relative;
  flex-shrink: 0;
  color: #444444;
  padding: 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .main-nav {
    flex: 1;
  }
  .main-nav .nav__link {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
.main-nav .nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 20px 0 0;
  box-sizing: border-box;
  flex-direction: column;
}
.main-nav .nav__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 1rem 0;
  text-align: center;
  font-weight: 500;
  font-size: 1.125rem;
  font-family: "Figtree", sans-serif;
}
.main-nav .nav__description {
  display: none;
}
.main-nav .nav__link {
  position: relative;
  display: block;
  padding: 0.75em 0em;
  overflow: hidden;
  justify-content: center;
  line-height: 1;
  font-weight: bold;
}
.main-nav .nav__link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #addb67;
  transition: height 0.15s ease-out;
  border-radius: 3px;
}
.main-nav .nav__link, .main-nav .nav__link:link, .main-nav .nav__link:visited {
  color: #444444;
  text-decoration: none;
}
.main-nav .nav__link:hover, .main-nav .nav__link:focus, .main-nav .nav__link:active {
  color: #637600;
}
.main-nav .nav__link:hover::after, .main-nav .nav__link:focus::after, .main-nav .nav__link:active::after {
  height: 6px;
}
.main-nav .nav__link.-active, .main-nav .nav__link.-active-parent {
  text-decoration: none;
}
.main-nav .nav__link.-active::after, .main-nav .nav__link.-active-parent::after {
  height: 6px;
}
.main-nav .nav__link.-active, .main-nav .nav__link.-active:link, .main-nav .nav__link.-active:visited, .main-nav .nav__link.-active-parent, .main-nav .nav__link.-active-parent:link, .main-nav .nav__link.-active-parent:visited {
  color: #444444;
}
@media screen and (min-width: 600px) {
  .main-nav .nav {
    flex-direction: row;
    padding-top: 0;
  }
}
@media screen and (min-width: 800px) {
  .main-nav .nav {
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 800px) {
  .sidebar nav.nav {
    margin-right: -10px;
  }
}
.sidebar .nav {
  padding: 0 0 0 0;
  margin-top: 0;
  list-style-type: none;
}
.sidebar .nav.-root-nav {
  padding-top: 2rem;
}
.sidebar .nav__upper_node_link {
  position: relative;
  font-family: "Figtree", sans-serif;
  font-size: 1.875rem;
  font-weight: 800;
}
.sidebar .nav__upper_node_link::before {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(calc(-50% + 2px));
  content: url("data:image/svg+xml;utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='11px' height='24px' style='fill:%23637600;transform:rotate(180deg)' viewBox='0 0 11 24' enable-background='new 0 0 11 24' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11,12L0,24l5.5-12L0,0L11,12z' fill='%23637600'/%3E%3C/svg%3E");
}
@media screen and (min-width: 800px) {
  .sidebar .nav__upper_node_link::before {
    left: -2vw;
  }
}
.sidebar .nav__upper_node_link, .sidebar .nav__upper_node_link:link, .sidebar .nav__upper_node_link:visited {
  color: #222526;
  text-decoration: none;
}
.sidebar .nav__upper_node_link:hover, .sidebar .nav__upper_node_link:focus, .sidebar .nav__upper_node_link:active {
  text-decoration: underline;
}
.sidebar .nav.nav.-sub-nav {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 24px;
}
.sidebar .nav__list {
  padding-left: 0em;
  list-style-type: none;
  margin: 2.2em 0 1.5em;
}
.sidebar .nav__item {
  font-size: 0.9375rem;
  font-weight: 600;
  margin: 0;
  /* &.-has-children {
  	> .nav__link {
  		font-weight: 500;
  	}
  } */
}
.sidebar .nav__item .nav__item {
  font-size: 1rem;
  font-weight: 300;
  text-transform: none;
}
.sidebar .nav__item.-expanded {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar .nav__item.-current {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar .nav__description {
  display: none;
}
.sidebar .nav__link {
  position: relative;
  display: block;
  padding: 8px 0;
}
.sidebar .nav__link + .nav.-sub-nav .nav__link.-expandable .nav__toggle-button {
  left: -26px;
  top: 0.36em;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='12' height='12' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5,5L5,1C5,0.448 5.448,0 6,0C6.552,0 7,0.448 7,1L7,5L11,5C11.552,5 12,5.448 12,6C12,6.552 11.552,7 11,7L7,7L7,11C7,11.552 6.552,12 6,12C5.448,12 5,11.552 5,11L5,7L1,7C0.448,7 0,6.552 0,6C0,5.448 0.448,5 1,5L5,5Z'/%3E%3C/svg%3E");
}
.sidebar .nav__link, .sidebar .nav__link:link, .sidebar .nav__link:visited {
  color: #474848;
  text-decoration: none;
}
.sidebar .nav__link:hover, .sidebar .nav__link:focus, .sidebar .nav__link:active {
  text-decoration: underline;
}
.sidebar .nav__link[aria-expanded=true] ~ .nav {
  display: block;
}
.sidebar .nav__link[aria-expanded=false] ~ .nav {
  display: none;
}
.sidebar .nav__link.-active, .sidebar .nav__link.-active-parent {
  text-decoration: none;
  font-weight: bold;
  position: relative;
}
@media screen and (min-width: 800px) {
  .sidebar .nav__link.-active::after, .sidebar .nav__link.-active-parent::after {
    content: "";
    position: absolute;
    width: 10px;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #637600;
  }
}
.sidebar .nav__link.-active, .sidebar .nav__link.-active:link, .sidebar .nav__link.-active:visited, .sidebar .nav__link.-active-parent, .sidebar .nav__link.-active-parent:link, .sidebar .nav__link.-active-parent:visited {
  color: #637600;
}
.sidebar .nav__toggle-button {
  font-size: 1rem;
  line-height: 0;
  position: absolute;
  transition: transform 0.2s ease-out;
  transform-origin: 50% 50%;
  left: -28px;
  top: 0.18em;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7,7L7,1C7,0.448 7.448,0 8,0C8.552,0 9,0.448 9,1L9,7L15,7C15.552,7 16,7.448 16,8C16,8.552 15.552,9 15,9L9,9L9,15C9,15.552 8.552,16 8,16C7.448,16 7,15.552 7,15L7,9L1,9C0.448,9 0,8.552 0,8C0,7.448 0.448,7 1,7L7,7Z'/%3E%3C/svg%3E") no-repeat 0 0;
}
.sidebar .nav[aria-expanded=true] .nav__toggle-button {
  transform: rotate(225deg);
}