.contact-form {
	padding-top: 0;

	.form__section {
		margin-bottom: 14px;
	}

	&__input,
	&__textarea {
		background-color: $contact-form-input-bg-color;
		border-color: $contact-form-input-border-color;
		color: $contact-form-input-color;
		border-radius: 0;

		&::placeholder {
			font-style: italic;
		}
	}

	&__input {
		min-height: 55px;
	}

	&__info:first-child {
		margin: 0.55em 0 0.9em;
	}

	&__button.-large.-primary {
		appearance: none;
		border-radius: 0;
		background-color: transparent;
		box-shadow: none;
		padding: 1em 0;
		color: $more-color;
		position: relative;
		font-weight: 700;
		font-size: rem(16px);
		transition: color 0.16s ease-in-out;
		min-height: initial;

		&::before {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			content: "";
			height: 6px;
			border-radius: 8px;
			background-color: $more-border-color;
			transform: scaleX(1);
			transition: height 0.16s ease-in-out, transform 0.16s ease-out;
			z-index: 0;
		}

		&::after {
			content: " zprávu";
		}

		&:not([disabled]) {
			@include link-over(&) {
				box-shadow: none;
				color: $more-color-hover;

				&::before {
					z-index: -1;
					height: 100%;
					transform: scaleX(1.3);
				}
			}
		}
	}
}
