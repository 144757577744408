.main-nav {
	position: relative;
	flex-shrink: 0;
	color: $main-nav-color;
	padding: 0 0 0;
	@include flex(df, aic, jcc);

	@include min(600px) {
		flex: 1;
		.nav__link {
			@include flex(df, aic, fww);
		}
	}

	.nav {
		@include flex(df, fww);
		margin: 0;
		padding: 20px 0 0;
		box-sizing: border-box;
		flex-direction: column;
		&__item {
			@include flex(dif, aic, jcc);
			flex-grow: 1;
			margin: 0 1rem 0;
			text-align: center;
			font-weight: 500;
			font-size: rem(18px);
			font-family: $font-headings;
		}

		&__description {
			display: none;
		}

		&__link {
			position: relative;
			display: block;
			padding: 0.75em 0em;
			overflow: hidden;
			justify-content: center;
			line-height: 1;
			font-weight: bold;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
				background-color: $main-nav-button-bg-hover;
				transition: height 0.15s ease-out;
				border-radius: 3px;
			}

			@include link(&) {
				color: $main-nav-color;
				text-decoration: none;
			}

			@include link-over(&) {
				color: $main-nav-color-hover;
				&::after {
					height: 6px;
				}
			}
			&.-active,
			&.-active-parent {
				text-decoration: none;

				&::after {
					height: 6px;
				}

				@include link(&) {
					color: $main-nav-color;
				}
			}
		}
		@include min(600px) {
			flex-direction: row;
			padding-top: 0;
		}

		@include min(800px) {
			flex-wrap: nowrap;
		}
	}
}
