#google_translate_element {
	display: inline-block;
	margin: 0;
	align-self: center;
	padding: 16px;
	border-radius: 12px;

	&.field {
		background-color: $gte-bg-color;
	}

	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
		background-color: transparent;
		display: flex;
		align-items: center;

		&,
		& * {
			font-family: $font-headings;
			font-size: rem(16px) !important;
		}

		.goog-te-gadget-icon {
			padding: 9.5px;
			order: 2;
			margin: 0;
			background: transparent encode-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.8 43.2" ffill="#{$gte-ico-color}"><path d="M23.067 34.241h-1.6c-2-.133-3.867-.666-5.467-1.733-3.333-2-5.333-4.933-6-8.667l-.133-1.466V20.64l.266-2c1.067-3.866 3.334-6.666 6.8-8.4 2.4-1.2 4.8-1.466 7.467-1.066 1.333.266 2.667.666 3.867 1.333.666.267 1.2.667 1.733 1.067l-2.933 3.866c-3.334-2.533-7.467-1.733-9.867.267-2.533 2.267-3.467 5.867-2 9.067a8.066 8.066 0 008.267 4.666c3.333-.533 5.6-2.933 6.266-5.333h-7.466v-4.933h12.666l-.133 3.733c-.267 2.667-1.333 5.067-3.067 7.067-2 2.266-4.4 3.6-7.333 4.133l-1.333.133"/></svg>') no-repeat 50% 50%/150%!important;
		}

		[aria-haspopup] {
			margin-right: 0;
			display: none;
			text-decoration: none !important;

			span {
				&:nth-of-type(1) {
					padding: 0 3px;
					display: inline-block;
					color: $search-color;
					text-decoration: underline;

					@include link-over(&) {
						text-decoration: none;
					}
				}

				&:nth-of-type(2) {
					padding-right: 3px;
					border-left: 0 !important;
				}

				&:nth-of-type(3) {
					display: none;
					background: none;
					color: $search-color !important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include max(599px) {

	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt+div>object,
	iframe.goog-te-menu-frame,
	iframe.goog-te-banner-frame {
		display: none !important;
	}
}
